<template>
  <div class="home">
    <aboutus />
  </div>
</template>

<script>
  import Aboutus from '../jiameng/childrenCpn/Aboutus'

  export default {
    name: "Home",
    components: { 
      Aboutus 
    },
  }
</script>

<style scoped>
.home {
  text-align: center;
  width: 100%;
  min-width: 1220px;
}
</style>

<template>
  <div class="subtitle">
    <div class="above-title">
      <slot name="above-title"></slot>
    </div>
    <div class="cut-off">
      <b></b>
      <img src="~assets/img/common/subtitle_cutoff.png" alt="">
      <b></b>
    </div>
    <div class="under-title">
      <slot name="under-title"></slot>
    </div>
  </div>
</template>

<script>
  export default {
    name: "Subtitle"
  }
</script>

<style scoped>
  .above-title {
    font-size: 40px;
    color: #444444;
    margin-bottom: 10px;
  }

  .cut-off b {
    background: #ebebeb;
    display: inline-block;
    width: 167px;
    height: 2px;
    vertical-align: middle;
    margin: 0 20px;
  }

  .cut-off img {
    display: inline-block;
    vertical-align: middle;
  }

  .under-title {
    font-size: 18px;
    color: #444444;
    margin-top: 20px;
  }
</style>

<template>
  <div class="about-us">
    <subtitle>
      <div slot="above-title">公司简介</div>
    </subtitle>
    <div class="div1">
      <div class="div1-left">
        <div class="intro-text">
          <p>
            深圳大富智慧健康科技有限公司是大富科技（股票：300134）旗下的全资子公司，是一家专注于中医科技创新、中医智能化升级的高新技术企业。
          </p>
          <p>
            公司将以智能硬件、云服务、AI智能系统为核心，打造业界领先的中医养生健康管理和大数据解决方案，形成智慧中医健康产业链。
          </p>
          <p>
            大富智慧健康立足传统医学，结合现代科技。致力于为中国传统医药学找到解决教授难、普及难、传播难的根本方法。大富养生隶属上市公司大富科技集团，其在健康养老、教育传媒、人工智能、特种机器人设备等领域深耕数十年。在集团孙尚传董事长精诚报国、力根本源、普惠万家核心理念的指引和激励下，大富智慧健康必将成为建立人类命运共同体的重要构成！
          </p>
        </div>
        <div class="btn-more"
             @click="moreClick">了解更多+</div>
      </div>
      <div class="div1-right">
        <img src="~assets/img/jiameng/intro.png"
             width="100%"
             alt="">
      </div>
    </div>
    <div style="width: 1150px; margin: 0 auto">
      <img src="~assets/img/jiameng/rect.png"
           width="100%"
           alt="">
    </div>
  </div>
</template>

<script>
import Subtitle from 'components/content/subtitle/Subtitle'

export default {
  name: 'Aboutus',
  components: {
    Subtitle,
  },
  methods: {
    moreClick() {},
  },
}
</script>

<style scoped>
.about-us {
  padding: 50px 0;
  /* width: 100%; */
}

.div1 {
  display: flex;
  justify-content: center;
  margin: 50px 0 60px;
}

.intro-text {
  width: 560px;
  line-height: 28px;
  overflow: hidden;
  text-align: left;
  font-size: 18px;
  color: #555555;
}
.intro-text p {
  text-indent: 2em;
  margin-bottom: 20px;
}

.btn-more {
  font-size: 20px;
  color: #555555;
  /* text-align: left; */
  border: 1px solid #555555;
  width: 186px;
  text-align: center;
  padding: 12px 0;
  border-radius: 25px;
}

.div1-right {
  border: 2px solid #ebebeb;
  padding: 10px;
  margin-left: 20px;
  width: 580px;
}
</style>
